import API, { getSSO } from './http-helper.service';
import { EXISTING_FORMS } from './form.helper.service';
import axios from 'axios';
import { GeneralConfig } from "../utility/Constant";
const qs = require('qs');

export default class FormBuilderService {
  constructor() {
	const accessToken = localStorage.getItem('access_token') ? 
	  localStorage.getItem('access_token') : '';
	const userSSO = localStorage.getItem('ge_sso') ? 
	  localStorage.getItem('ge_sso') : '';
	this.state = {
	  accessToken: accessToken, 
	  config: { headers: { 'Authorization': `Bearer ${accessToken}` } },
	  userSSO: userSSO
	}
  }
  
  getMyForms() {
    /*this path needs to be replaced once the actual api to fetch user-specific 
	  forms is built*/
    return API.get('builder/forms/metadata', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  isValidName(formName) {
	let baseUrl = 'builder/forms/IsFormNameExist/';
    return API.get(baseUrl + formName, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getDefaultLanguages() {
    return API.get('language', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getTranslatedForm(translateData) {
    let baseUrl = 'builder/forms/translate';
    return API.post(baseUrl, translateData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  searchByFormName(searchQuery, sortParameter, currentPage, sso = '') {
    let baseUrl = 'builder/forms/search?page=' + (currentPage - 1) + '&size=' + 
    	EXISTING_FORMS.SIZE;
    if(sso) {
      baseUrl = baseUrl +  '&userId=' + sso;
    }

    if (sortParameter.value && sortParameter.value !== "") {
      baseUrl = baseUrl + "&sort=" + sortParameter.value + "," + 
      	sortParameter.order;
    }
    return API.post(baseUrl, searchQuery, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  deleteForm(formId) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
    let baseUrl = 'builder/forms/' + formId;
    return API.delete(baseUrl, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllForms(currentPage = 1, sortParameter = {}, sso = '') {
    let baseUrl = 'builder/forms/metadata?page=' + (currentPage - 1) + 
    	'&size=' + EXISTING_FORMS.SIZE;
    if(sso) {
      baseUrl = baseUrl +  '&userId=' + sso;
    }
    if (sortParameter.value && sortParameter.value !== "") {
      baseUrl = baseUrl + "&sort=" + sortParameter.value + "," + 
      	sortParameter.order;
    }
    return API.get(baseUrl, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllPublishedFormNames() {
    return API.get('builder/forms/getFormName', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getForm(formId) {
    return API.get('builder/forms/' + formId, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getFormCategories(formId) {
    return API.get('category/form/' + formId, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUniversalTypes() {
    return API.get('conditional/universalType', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getDependentDropdowns() {
    return API.get('dynamicDropdown', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  convertToFormData(form) {
    const currentTimestamp = new Date().valueOf();
    let categories = [];
    categories.push({ "id": form.business });
    if (form.subbusiness) categories.push({ "id": form.subbusiness });
    if (form.group) categories.push({ "id": form.group });
    if (form.subgroup) categories.push({ "id": form.subgroup });
    if (form.groupIds === undefined) form.groupIds = [];
    const formData = {
      "id": form.id, "formCode": form.formCode, "version": form.version,
      "publishedversion": form.publishedversion, "formStatus": form.formStatus,
      "title": form.title, "assetType": "asset",
      "description": form.description, "defaultLanguageCode": form.language,
      "createdBy": this.state.userSSO, "createdDate": currentTimestamp,
      "lastUpdatedBy": this.state.userSSO, "lastUpdatedDate": currentTimestamp,
      "keywords": form.keywords, "children": [], "requests": [],
      "responses": null, "formCategories": categories, "enforceOrder": null,
      "copiedFromFormCode": null, "copiedFromFormVersion": null,
      "customFormCode": null, "isInLine": false, "componentType": "form",
      "hashCode": -641280495, "groupIds": form.groupIds,
      "formRegion": form.formRegion, "formSiteRegion": form.formSiteRegion
    };
    return formData;
  }

  createForm(form) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
	const currentTimestamp = new Date().valueOf();
    let categories = [];
    categories.push({ "id": form.business });
    if (form.subbusiness) categories.push({ "id": form.subbusiness });
    if (form.group) categories.push({ "id": form.group });
    if (form.subgroup) categories.push({ "id": form.subgroup });
    if (form.groupIds === undefined) form.groupIds = [];
    const formData = {
      "version": null, "publishedversion": null, "title": form.title,
      "assetType": "asset", "formStatus": "Draft",
      "description": form.description, "defaultLanguageCode": form.language,
      "createdBy": this.state.userSSO, "createdDate": currentTimestamp,
      "lastUpdatedBy": this.state.userSSO, "lastUpdatedDate": currentTimestamp,
      "keywords": form.keywords, "children": [], "requests": [],
      "responses": null, "formCategories": categories, "enforceOrder": null,
      "copiedFromFormCode": null, "copiedFromFormVersion": null,
      "customFormCode": null, "isInLine": false, "componentType": "form", 
      "hashCode": -641280495, "groupIds": form.groupIds, 
      "formRegion": form.formRegion, "formSiteRegion": form.formSiteRegion
    };
    return API.post('builder/forms', formData, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  updateForm(formId, form) {
  let userId = getSSO();
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
    const currentTimestamp = new Date().valueOf();
    form.lastUpdatedDate = currentTimestamp;
	form.lastUpdatedBy = userId;
    const updateURL = 'builder/forms/' + formId;
    return API.put(updateURL, form, config).then(res => {
      return res;
    }, error => {
      return { 'error': error };
    });
  }

  updateTranslateForm(formId, translateForm) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
    const currentTimestamp = new Date().valueOf();
    translateForm.lastUpdatedDate = currentTimestamp;
    const updateURL = 'builder/forms/' + formId;
    return API.put(updateURL, translateForm, config).then(res => {
      return res;
    }, error => {
      return { 'error': error };
    });
  }

  copyForm(form) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
    const copyURL = 'builder/forms/draft';
    const formData = this.convertToFormData(form);
    return API.post(copyURL, formData, config).then(res => {
      return res;
    }, error => {
      return { 'error': error };
    });
  }

  getBarcodeTypes() {
    return API.get('customDataTypes', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  createUserGroup(groupData) {
	let baseURL = 'builder/groups';
    return API.post(baseURL, groupData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  editUserGroup(groupData) {
	let baseURL = 'builder/groups/' + groupData.id;
    return API.put(baseURL, groupData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserGroups() {
    return API.get('builder/groups', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  deleteUserGroup(groupId) {
	let baseURL = 'builder/groups/delete/' + groupId;
    return API.put(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserGroup(groupId) {
    return API.get('builder/groups/' + groupId, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllRegions() {
    return API.get('builder/assetMgmt/region', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllSitesByRegion(regionId) {
	let baseURL = 'builder/assetMgmt/region/' + regionId + '/sites';
    return API.get(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllSites() {
	let authorization = this.state.config.headers.Authorization;
	let config = { 
	  headers: { Authorization: authorization, tenantId: 'geservdrZp'} 
	};
    return API.get('builder/assetMgmt/sites', config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllOptionalEquipments() {
    return API.get('optionalEquipment', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getOptionalEquipmentMapping(siteId) {
	let baseURL = 'optionalEquipment/mapping?siteId=' + siteId;
    return API.get(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAllUniqueCodes() {
    return API.get('builder/unique-codes', this.state.config);
  }

  getAllTurbinesBySite(siteId) {
	let baseURL = 'builder/assetMgmt/turbines?siteId=' + siteId;
    return API.get(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  deleteOptionalEquipmentMapping(optionalEquipmentId) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId } };
	let baseURL = 'optionalEquipment/delete-by-id';
    return API.put(baseURL, {optionalEquipmentId: optionalEquipmentId}, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  createNewMapping(payload) {
	let userId = this.state.userSSO;
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { Authorization: authorization, userId: userId} };
    const currentTimestamp = new Date().valueOf();
    const mappingData = {
      "siteId": payload.siteId, "turbineId": payload.turbineId,
      "optEquipId": payload.optionalEquipId, "isActive": true,
      "tenantId": "ren", "createdBy": this.state.userSSO, 
      "createdDate": currentTimestamp, "lastUpdatedBy": this.state.userSSO, 
      "lastUpdatedDate": currentTimestamp
    };
    let baseURL = 'optionalEquipment';
    return API.post(baseURL, mappingData, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserDetails() {
    return API.get('user-mgmt/users', this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserSSODetails(sso) {
	let baseURL = 'user-mgmt/users/ldap/' + sso;
    return API.get(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  addNewUser(payload) {
    let today = new Date();
    let month = "" + (today.getMonth() + 1);
    if (today.getMonth() + 1 < 10) month = "0" + month;
    let day = "" + (today.getDate());
    if (today.getDate() < 10) day = "0" + day;
    let createdDate = today.getFullYear() + "-" + month + "-" + day;
    const userData = {
      "sso": payload.sso, "userFirstName": payload.firstName,
      "userLastName": payload.lastName, "email": payload.email,
      "userRole": payload.role, "tenantId": "ren", 
      "createdBy": this.state.userSSO, "updateBy": this.state.userSSO, 
      "createdDate": createdDate, "delete": false
    };
    let baseURL = 'user-mgmt/users';
    return API.post(baseURL, userData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  updateUser(payload){
    let today = new Date();
    let month = "" + (today.getMonth() + 1);
    if (today.getMonth() + 1 < 10) month = "0" + month;
    let day = "" + (today.getDate());
    if (today.getDate() < 10) day = "0" + day;
    let updatedDate = today.getFullYear() + "-" + month + "-" + day;
    const userData = {
      "id": payload.id, "sso": payload.sso, 
      "userFirstName": payload.userFirstName, 
      "userLastName": payload.userLastName, "email": payload.email,
      "userRole": payload.userRole, "tenantId": "ren",
      "createdBy": payload.createdBy, "updateBy": this.state.userSSO,
      "createdDate": payload.createdDate, "updatedDate": updatedDate,
      "delete": false
    };
    return API.put('user-mgmt/users', userData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  deleteUser(userIds) {
	let baseURL = 'user-mgmt/users?id=' + userIds;
    return API.delete(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserInfo(code) {
	let baseURL = 'user-mgmt/users/userinfo/' + code;
    return API.get(baseURL, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getUserDetailsBySSO(sso) {
    return API.get('user-mgmt/users/' +sso, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  getAccessToken(code) {
    const TOKEN_URL = process.env.REACT_APP_TOKEN_URL;
    const client_id = process.env.REACT_APP_CLIENT_ID; 
    const data = {
      grant_type: 'authorization_code',
      client_id: client_id,
      code: code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL
    };

	  return axios.request({
	    method: 'POST',
	    url: TOKEN_URL,
	    data: qs.stringify(data),
	    headers: { 
	    	'Accept':'application/json', 
	    	'Content-Type': 'application/x-www-form-urlencoded' 
	    }
	  }).then(function(res) { return res; });
  }

  getRefreshToken(refreshToken) {
    const TOKEN_URL = process.env.REACT_APP_TOKEN_URL;
    const client_id = process.env.REACT_APP_CLIENT_ID; 
    const data = {
      grant_type: 'refresh_token',
      client_id: client_id,
      refresh_token : refreshToken
    };

	  return axios.request({
	    method: 'POST',
	    url: TOKEN_URL,
	    data: qs.stringify(data),
	    headers: { 
	    	'Accept':'application/json', 
	    	'Content-Type': 'application/x-www-form-urlencoded' 
	    }
	  }).then(function(res) { return res; });
  }


  
  uploadImageDetails(fileObj) {
	let accept = 'application/json';
	let contentType ='multipart/form-data'; 
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { 
	  'Authorization': authorization, 'Accept': accept, 
	  'Content-Type': contentType
	} };
	var formData = new FormData();
	  formData.append('file', fileObj.file);
    let imageId = fileObj.imageId;
    let url = 'images/uploadImage?imageId=' + imageId + '';
    return API.post(url, formData, config)
      .then((response) => { return response.data; })
      .catch((error) => { return undefined; });
  }
  
  imageDetails(id, action) {
	let accept = 'application/json';
	let contentType ='multipart/form-data'; 
	let authorization = this.state.config.headers.Authorization;
	let config = { headers: { 
		'Authorization': authorization, 'Accept': accept, 
		'Content-Type': contentType
	} };
	if(action === GeneralConfig.DELETE)
	  return API.delete('images/' + id + '', config)
	  .then((response) => { return response; })
	  .catch((error) => { return undefined; });
	else if(action === GeneralConfig.GET)
	  return API.get('images/' + id + '', config)
	  .then((response) => {
	    let data = response.data;
	    data.imageId = id;
	    return data;
	  })
	  .catch((error) => { return undefined; });
  }
}
