import axios from 'axios';
import FormBuilderService from '../services/form-builder.service';

const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
const instance = axios.create({ baseURL: BASE_URL_V2 });

instance.defaults.headers.common['tenantId'] = 'ren';

instance.interceptors.request.use(
	config => {
	  const token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	  if (token) {
		config.headers['Authorization'] = 'Bearer ' + token
	  }
	  return config
	},
	error => {
	  Promise.reject(error)
	}
  )
   
const refreshAndRetryQueue = [];
let isRefreshing = false;
let newAccessToken = '';
let isTokenCall = false;
  
instance.interceptors.response.use(
	(response) => response,
	  async (error) => {
		  const originalRequest = error.config;
		  if (error.response && error.response.status === 401) {
			  if (!isRefreshing) {
				  isRefreshing = true;
				  try {
					  if (!isTokenCall) {
						  isTokenCall = true;
						  const formBuilderService = new FormBuilderService();
						  const refToken = localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : '';
						  if (refToken) {
							  await formBuilderService.getRefreshToken(refToken).then((response) => {
								  if (response && response.status === 200) {
									   newAccessToken = updateToken(response);
								  } else {
									  clearStorage();
								  }
							  });
						  } else {
							  clearStorage();
						  }
					  } else {
						  newAccessToken = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
					  }
  
					  error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

					  refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
						  instance
							  .request(config)
							  .then((response) => resolve(response))
							  .catch((err) => reject(err));
					  });
  
					  refreshAndRetryQueue.length = 0;
					  return instance(originalRequest);
				  } catch (refreshError) {
					  clearStorage();
					  throw refreshError;
				  } finally {
					  isRefreshing = false;
					  isTokenCall = false;
				  }
			  }
  
			  return new Promise((resolve, reject) => {
				  refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
			  });
		  }
		  return Promise.reject(error);
	  }
  );

const clearStorage = ()=> {
    localStorage.clear();
	window.location.href = process.env.REACT_APP_SSO_LOGOUT;
}

const updateToken = (response) =>{
	 let accessToken = (response.data && response.data.access_token) ? response.data.access_token : '';
	 localStorage.setItem('access_token', accessToken);
	 return accessToken; 
}

export const getSSO = () => {
	return localStorage.getItem("ge_sso");
}

export default instance;