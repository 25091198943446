import { BehaviorSubject } from "rxjs";
import { jwtDecode } from "jwt-decode";
import { dateHelperService } from "../services/date-helper.service";

var currentUser = {};
const currentUserState = new BehaviorSubject(currentUser);

class SessionManager {
  static _instance = null;

  constructor() {
    this.user = {};
  }

  static sharedInstance() {
    if (SessionManager._instance == null) {
      SessionManager._instance = new SessionManager();
    }
    return this._instance;
  }

  updateCurrentUser = (currentUser) => {
    this.user = currentUser;
    currentUserState.next(currentUser);
  };
  isValidSession = async () => {
    let accessToken = localStorage.getItem("access_token");
    let userSSO = localStorage.getItem("ge_sso");
    if (accessToken && userSSO) {
      let decodedToken = jwtDecode(accessToken);
      let currentTime = new Date();
      let expTime = dateHelperService.unixTimestampToDateTime(decodedToken.exp);
      if (expTime > currentTime) {
        return true;
      } else {
        let refresh_token = localStorage.getItem("refresh_token");
        try {
          let response = await this.getRefreshToken(refresh_token);
          if (response && response.status === 200) {
            localStorage.setItem("access_token", accessToken);
            return true;
          }
        } catch {
          this.logOut();
        }
      }
    }
    return false;
  };

  logOut = () => {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_SSO_LOGOUT;
  };
}

export { SessionManager, currentUserState };
