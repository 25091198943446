import { Box, Chip, Grid, Typography, IconButton, Button, Menu, MenuItem, ListItemText, ListItemIcon, TextField, Divider, Toolbar, Tooltip,Card } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { formState, FormStore, loaderState, currentSectionState, formErrorState} from '../../store/form.store';
import { styled, withStyles } from '@material-ui/styles';
import Components from '../form-element/CompMap';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ConfirmationDialog from '../form-element/ConfirmationDialog';
import { FormApproveMessage, FormDeleteMessage, FORM_STATUS,TRANSFER_TEXT } from '../../utilities/Constants';
import { MoreHoriz, KeyboardReturnOutlined } from '@material-ui/icons';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { FORM_MENU_OPTIONS } from '../../../services/review-portal-services/review-form.helper.service';
import { labelStyle } from '../../utilities/Constants';
import { Loader } from '../../../components/Loader';
import { currentUserState } from '../../../services/session.manager';
import SearchDialog from '../../../components/form-element/SearchDialog';
import { htmlBase64Utils } from '../../../utility/CommonUtils';
import { dateHelperService } from '../../../services/date-helper.service';
import { NA } from "../../../constants/Constants"
import { Autocomplete } from '@material-ui/lab';
import ReviewPortalService from '../../../services/review-portal-services/review-portal.service';
import { toast } from 'react-toastify';
import transferIcon from '../../../shared/imgs/transferIcon.svg';


const styles = theme => ({
    formStatus: {
        padding: '7px'
    },
    formLabel:{
        marginTop:'18%'
    },
    title: {
        width: '100%', fontWeight: 'bold', padding: '7px', paddingLeft: '18px'
    },
    iconStyle:{
        float:'right', marginTop: '-2%'
    },
    root: {
        width: '100%',
        maxHeight: '600px',
        height: '600px',
        overflowY: 'auto',
    },
    approveBtn: {
        borderRadius: 3,
        padding: 7,
        marginLeft: 0,
    },
    listIcon: {
        minWidth: 35,
    },
    button: {
        margin: '5px',
        marginLeft: '25%',
    },
    divider: {
        marginBottom: '5px'
    },
    toolBar: {
        backgroundColor: '#6F7282',
        position: 'sticky',
        top: 'auto',
        bottom: 0,
        zIndex: 5,
        width: '100%',
        marginLeft: '1px'
    },
    flexGrow: {
        flexGrow: 1,
    },
    saveButton: {
        color: 'white',
        border: '2px solid #FFFFFF !important'
    },
    menuItem: {
        "&:hover": {
            color: "#419BAC",
            backgroundColor: "#eff7f8"

        }
    },    
    loader:{
        textAlign: 'center',
        position: 'absolute',
        top: '30%',
        left: '50%'
    },
    name:{
        fontFamily:'RobotoLight',
        fontSize:'14px',
        color:'#212121'
    },

    value:{
        color:'#212121',
        fontFamily:'RobotoLight',
        fontSize:'14px',
        fontWeight:'600',
    },
    gridContainer:{
        gap:'16px',
        padding:'16px 20px'
    },
    card:{
        marginLeft:"16px",
        marginTop:'16px'
    },
    box:{
        display:'flex', 
        flexDirection:'column'
    }, 
    formHeading:{
        fontFamily:'RobotoLight',
        fontWeight:"600",
        fontSize:"16px",
        paddingTop:"16px",
        paddingLeft:"20px",
    }  
});

const TitleTooltip = withStyles({
    tooltip: {
        fontSize: "13px",
    }
})(Tooltip);

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
}));

class FormView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {},
            loading: false,
            open: false,
            title: '',
            description: '',
            type: '',
            anchorEl: null,
            showComment: false,
            comment: '',
            formStatus: '',
            hasError: false,
            onHoveredTitle: false,
            editMode: false,
            disableSave: false,
            userRole: '',
            ssoOptionsList:[],transferedUserId:""
        };
        this.formMenuOptions = FORM_MENU_OPTIONS;
        this.reviewPortalService = new ReviewPortalService();
    }

    componentDidMount() {
        this.formSubscription = formState.subscribe((response) => {
            htmlBase64Utils(response, false);
            this.setState({
                form: response
            });
        });
        this.loaderSubscription = loaderState.subscribe((response) => {
            this.setState({
                loading: response
            });
        })
        this.currentSectionSubscription = currentSectionState.subscribe(res => {
            this.setState({ editMode: res ? true : false});
        });
        this.formErrorObservable = formErrorState.subscribe((response) => {
            this.setState({
                disableSave: Object.keys(response).length === 0 ? false : true
            });
        });
        this.currentUserSubscription = currentUserState.subscribe(user => {
            this.setState({ userRole: user.userRole });
        });
    }

    componentWillUnmount() {
        this.formSubscription.unsubscribe();
        this.loaderSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
        this.currentUserSubscription.unsubscribe();
        this.formErrorObservable.unsubscribe();
        this.props.handleIsLoading();
    }

    handleDelete = () => {
        this.setState({
            type: 'delete',
            open: true,
            title: 'DELETE FORM',
            description: FormDeleteMessage
        });
    }

    handleTransfer = () => {
        this.setState({
            type: TRANSFER_TEXT,
            open: true,
            title: 'TRANSFER FORM',
            description:""
        });
    }

    handleApprove = () => {
        this.setState({
            type: 'approve',
            open: true,
            formStatus: 'Approved',
            title: 'APPROVE FORM',
            description: FormApproveMessage
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    deleteForm = () => {
        if (this.state.form !== undefined) {
            const selectedForm = this.state.form;
            FormStore.sharedInstance().deleteForm(selectedForm.formDataId);
            this.handleClose();
          }
          this.props.handleIsLoading();
    }

    downloadForm = () => {
        if (this.state.form !== undefined) {
            const selectedForm = this.state.form;
            FormStore.sharedInstance().downloadForm(selectedForm);
        }
        this.props.handleIsLoading();
    }

    approveForm = () => {
        var payload = {};
        if (this.state.form !== undefined) {
            const selectedForm = this.state.form;
            payload.id = selectedForm.formDataId;
        } else {
            payload.id = '';
        }
        payload.formStatus = this.state.formStatus;
        payload.comments = this.state.comment;
        FormStore.sharedInstance().approveForm(payload);
        this.formSubscription = formState.subscribe((response) => {
            this.setState({
                form: response
            });
        });
        this.handleClose();
        this.props.handleIsLoading();
    }

    onSelectRejectForm = (e) => {
        this.setState({
            showComment: true,
            formStatus: 'Rejected'
        });
    }

    changeHandler = (event) => {
        this.setState({
            comment: event.target.value
        });
    }

    rejectForm = () => {
        const isValid = this.validateRejectForm();
        if (isValid) {
            var payload = {};
            if (this.state.form !== undefined) {
                const selectedForm = this.state.form;
                payload.id = selectedForm.formDataId;
            } else {
                payload.id = '';
            }
            payload.formStatus = this.state.formStatus;
            payload.comments = this.state.comment;
            FormStore.sharedInstance().rejectForm(payload);
            this.formSubscription = formState.subscribe((response) => {
                this.setState({
                    form: response
                });
            });
        }
        this.setState({
            hasError: true
        });
        this.props.handleIsLoading();
    }

    validateRejectForm = () => {
        var valid = false;
        if (this.state.form.formDataId && this.state.comment && this.state.formStatus) {
            valid = true;
        }
        return valid;
    }

    getHelperTextForComment = (value) => {
        let helperText = '';
        if (this.state.hasError) {
            if (!value) {
                helperText = 'Comment is required!';
            } else {
                return;
            }
        }
        return helperText;
    }

    showFormMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    cancelForm = () => {
        this.setState({
            comment: ''
        });
        this.handleCloseMenu();
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    }

    onClickFormName = () => {
        this.setState({
            onHoveredTitle: true
        });
    }

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null,
            hasError: false,
            showComment: false,
            comment : ''
        });
    }

    resetForm = () => {
        FormStore.sharedInstance().resetForm();
        this.setState({ editMode: false });
        this.props.handleIsLoading();
    }

    saveForm = () => {
        FormStore.sharedInstance().saveForm();
        this.props.handleIsLoading();
    }

    getCategory = (name) => {
            let groupCategory = "";
            this.state.form?.formCategories?.filter((formCategory) => {
              if (formCategory.categoryType === name) {
                groupCategory = formCategory.categoryName;
              }
              return groupCategory;
            });
            return groupCategory !== "" ? groupCategory : NA;
          };


    // Transform Autocomplete onChange
    handleTransformChange = (newValue) => {
        if(newValue.length>0){
            this.reviewPortalService.getAllUsersListAutoComplete(newValue).then(response => {
                this.setState({
                    ssoOptionsList: response
                });
            })
        }else{
            this.setState({
                ssoOptionsList: []
            });
        }
    }

    // Handle selected option from the Email or SSO
    handleTransferId=(selecetedOption)=>{
        this.setState({
            transferedUserId: !selecetedOption ? {} : selecetedOption
        });
    }


    // Submit Transfer Form
    handleApproveTransferForm = () => {
        let formDetails={
            formDataId:this.state.form.formDataId,
            transferedUserId:parseInt(this.state.transferedUserId.id)
        }
        this.reviewPortalService.approveTransferForm(formDetails).then((response)=>{
            if (response.status !== 200) {
                toast.error("Could not transform the form");
                return
            }
            toast.success("Form Transferred successfully");
            this.setState({
                transferedUserId: {},
                ssoOptionsList:[]
            });
            // Re-Trigger the Card Details Api
            this.props.getCheckedCardDetails(this.state.form.formDataId)
            this.handleClose();
        })
    }
    render() {
        const { classes } = this.props;
        const dataSections = [
          {
            label: "Status:",
            value: this.state.form?.formStatus,
            color:
              this.state.form?.formStatus?.toUpperCase() === FORM_STATUS.COMPLETED?.toUpperCase()
                ? "#001096"
                : this.state.form?.formStatus?.toUpperCase() === FORM_STATUS.REJECTED?.toUpperCase()
                ? "#FE1919"
                : this.state.form?.formStatus?.toUpperCase() === FORM_STATUS.APPROVED?.toUpperCase()
                ? "#22B40C"
                : this.state.form?.formStatus?.toUpperCase() === FORM_STATUS.INPROGRESS?.toUpperCase()
                ? "#E9745D"
                : "black",
            fontWeight: "600",
            textTrasform:"capitalize",
            xs: 2,
          },
          {
            label: "Windfarm:",
            value: this.state.form?.siteName || NA,
            xs: 3,
          },
          {
            label: "Record Id:",
            value: this.state.form?.formDataId || NA,
            xs: 2,
          },
          {
            label: this.state.form?.formStatus===FORM_STATUS.INPROGRESS?"Last updated by:":"Completed By",
            value:
              this.state.form?.submittedBy?.userFirstName +
                " " +
                this.state.form?.submittedBy?.userLastName || NA,
            variant: "body2",
            xs: 3,
          },
          {
            label: "Group",
            value: this.getCategory("Group"),
            xs: 2,
          },
          {
            label: "Turbine",
            value: this.state.form?.turbineId || NA,
            xs: 3,
          },
          {
            label: "Version:",
            value: this.state.form?.version || NA,
            xs: 2,
          },
          {
            label: this.state.form?.formStatus===FORM_STATUS.INPROGRESS?"Last updated date":"Completed date",
            value: dateHelperService.formattedDateFromUTS(
              this.state.form?.updatedDate
            ),
            xs: 3,
          },
        ];
        return (
          <Fragment>
            <FormTitle id="formTitle" title={this.state.form.form_name} form={this.state.form} className={classes}
                    deleteForm={this.deleteForm} handleClose={this.handleClose} open={this.state.open}
                    popupTitle={this.state.title} description={this.state.description} type={this.state.type}
                    handleDelete={this.handleDelete} handleTransfer={this.handleTransfer}
                    handleApprove={this.handleApprove} approveForm={this.approveForm}
                    rejectForm={this.rejectForm} showFormMenu={this.showFormMenu} anchorEl={this.state.anchorEl}
                    onSelectRejectForm={this.onSelectRejectForm} downloadForm={this.downloadForm} showComment={this.state.showComment} comment={this.state.comment}
                    changeHandler={this.changeHandler} getHelperTextForComment={this.getHelperTextForComment} cancelForm={this.cancelForm}
                    hasError={this.state.hasError} stopPropagation={this.stopPropagation.bind(this)}
                    onClickFormName={this.onClickFormName} onHoveredTitle={this.state.onHoveredTitle} userRole={this.state.userRole}
                    handleCloseMenu={this.handleCloseMenu} ssoOptionsList={this.state.ssoOptionsList} transferedUserId={this.state.transferedUserId}handleTransformChange={this.handleTransformChange} handleTransferId={this.handleTransferId} handleClearFormAutoComplete={this.handleClearFormAutoComplete} handleApproveTransferForm={this.handleApproveTransferForm} />
                <Divider style={{ marginTop: '2px' }} />
                {Object.keys(this.state.form).length>0 &&
                <Card className={classes.card}>
                    <Box className={classes.formHeading}>{this.state.form.formName}</Box>
                  <Grid className={classes.gridContainer} container >
                      {dataSections.map((section, index) => (
                        <Grid item xs={section.xs} key={index}>
                          <Box className={classes.box}>
                            <Typography
                              variant='caption'
                              color='textSecondary'
                              className={classes.name}
                            >
                              {section.label}
                            </Typography>
                            <Typography
                              variant={section.variant || 'caption'}
                              style={{
                                color: section.color || 'textSecondary',
                                fontWeight: '600',
                                fontFamily: section.fontFamily || 'RobotoLight',
                              }}
                              className={classes.value}
                            >
                            { section.value}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Card>
                }
            <Typography component="div" style={{paddingRight:'0px'}}>
                    <Box p={3} className={classes.root} style={{paddingRight:'0px'}} >
                        <Grid container spacing={3} style={{padding:'0px'}}>
                            <Grid item md={12} xs={12} style={{paddingRight:'0px'}}>
                                <RenderForm form={this.state.form} />
                            </Grid>
                        </Grid>
                    </Box>
              <div className={classes.loader}>
                <Loader loading={this.state.loading} />
              </div>
                    {((this.state.editMode) && (this.state.userRole !== 'TECHNICIAN')) ? (
                <Toolbar className={classes.toolBar}>
                  <span className={classes.flexGrow} />
                            <Button onClick={this.resetForm} style={{ color: 'white' }}>CANCEL</Button>
                            <Button onClick={this.saveForm} disabled={this.state.disableSave} className={classes.saveButton} variant="outlined">SAVE</Button>
                </Toolbar>
              ) : null}
            </Typography>
            </Fragment>);
    }
}

function FormTitle(props) {
    const classes = props.className;
    const title = (props.title !== undefined) ? props.title : '';
    const formStatus = props.form.formStatus;
    const formDataId = props.form.formDataId;
    const userRole = props.userRole;
    return (
        <Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={8} md={8}>
                    </Grid> 
                    <Grid item xs={4} md={4}>
                        {userRole !== 'TECHNICIAN' ?
                            <Item>
                                <span className={classes.iconStyle}>
                                    <IconButton id='iconButton' aria-label="threeDots"
                                        onClick={props.showFormMenu}>
                                        <MoreHoriz id='moreHorizIcon' />
                                    </IconButton>
                                    <SearchDialog
                                        open={Boolean(props.anchorEl)}
                                        onCloseMenu={props.handleCloseMenu}
                                        name="rejectFormMenu"
                                        anchorEl={props.anchorEl} title="" description="">
                                        <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} disabled={props.form.formStatus !== FORM_STATUS.INPROGRESS} onClick={props.handleTransfer} >
                                            <ListItemIcon className={classes.listIcon}>
                                            <img
                                                id="transferIcon"
                                                alt="Transfer Icon"
                                                src={transferIcon}
                                                className={classes.imgStyle}
                                            />
                                            </ListItemIcon>
                                            <ListItemText className={classes.menuItemText} primary="Transfer Form" disableTypography></ListItemText>
                                        </MenuItem>
                                        <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }}
                                            onClick={props.onSelectRejectForm} disabled={props.form.formStatus !== FORM_STATUS.COMPLETED}>
                                            <ListItemIcon className={classes.listIcon}>
                                                <KeyboardReturnOutlined id='keyboardreturnIcon' />
                                            </ListItemIcon>
                                            <ListItemText className={classes.menuItemText} primary="Reject Form"></ListItemText>
                                        </MenuItem>
                                        {props.showComment ?
                                            <Box p={3}>
                                                <Grid item>
                                                    <TextField fullWidth label="Comment"
                                                        multiline minRows="2"
                                                        name="comment"
                                                        variant="outlined"
                                                        value={props.comment}
                                                        error={props.hasError && (!props.comment.length)}
                                                        helperText={props.getHelperTextForComment(props.comment)}
                                                        onChange={props.changeHandler}
                                                        onKeyDown={props.stopPropagation} />
                                                </Grid>
                                                <div className={classes.buttonsView}>
                                                    <Button variant="text" className={classes.button} onClick={props.cancelForm}>
                                                        CANCEL
                                                    </Button>
                                                    <Button variant="text" color='primary' onClick={props.rejectForm}>
                                                        REJECT
                                                    </Button>
                                                </div>
                                                <Divider className={classes.divider} />
                                            </Box> : null}
                                        <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={props.downloadForm}>
                                            <ListItemIcon className={classes.listIcon}>
                                                <SaveAltIcon id='downloadIcon' />
                                            </ListItemIcon>
                                            <ListItemText className={classes.menuItemText} primary="Download" disableTypography></ListItemText>
                                        </MenuItem>
                                        <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={props.handleDelete}>
                                            <ListItemIcon className={classes.listIcon}>
                                                <DeleteOutlinedIcon id='deleteIcon' />
                                            </ListItemIcon>
                                            <ListItemText className={classes.menuItemText} primary="Delete"></ListItemText>
                                        </MenuItem>
                                    </SearchDialog>
                                    {
                                        props.form.formStatus===FORM_STATUS.COMPLETED&&
                                        <Button variant="contained" color='primary' className={classes.approveBtn} onClick={props.handleApprove}>Approve</Button>
                                    }
                                </span>
                            </Item> : null}
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={Boolean(props.open)}
                onClose={props.handleClose}
                title={props.popupTitle}
                description={props.description}
                onDelete={props.deleteForm}
                onApprove={
                    props.form.formStatus === FORM_STATUS.INPROGRESS?props.handleApproveTransferForm : props.approveForm
                }
                type={props.type}
                buttonTitle={props.type===TRANSFER_TEXT&&TRANSFER_TEXT}
                mainButtonDisabled={props.type===TRANSFER_TEXT&&!props.transferedUserId.id}
                dialogWidth={props.type===TRANSFER_TEXT&&"400px"}
                transferButton={props.type===TRANSFER_TEXT}
                >
                    {
                        props.form.formStatus === FORM_STATUS.INPROGRESS&&props.type===TRANSFER_TEXT&&
                        <Autocomplete
                            style={{
                                padding:"10px 0px"
                            }}
                            options={props.ssoOptionsList}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.id === value.id}  
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Email Or SSO" />}
                            onInputChange={(_event,newValue)=>props.handleTransformChange(newValue)
                            }
                            onChange={(_event,value)=>props.handleTransferId(value)}
                        />
                    }
            </ConfirmationDialog>
        </Fragment >)
}

function RenderForm(props) {
    if (props.form.children !== undefined && props.form.children.length !== 0) {
        const TagName = Components['section'];
        const items = props.form.children.map((item, key) =>
            <TagName key={key} section={item} formStatus={props.form.formStatus}/>
        );
        return items;
    }
    return (<div>Empty Form</div>);
}

export default withStyles(styles, { withTheme: true })(FormView);
