import { BehaviorSubject } from 'rxjs';
import FormBuilderService from '../services/form-builder.service';
import { FormHelperService } from './form.helper.service';
import { toast } from 'react-toastify';
import { formListService } from './form-list.service';
import { htmlBase64Utils } from '../utility/CommonUtils';

export const FORM_STATUS = Object.freeze({
  DRAFT: "Draft",
  PUBLISHED: "Published",
});

var expandedSectionId = undefined;
var translateSectionId = undefined;
var activeElementId = undefined;
var activeTranslateElementId = undefined;
var currentFormId = undefined;
var currentSubsectionId = undefined;
var translateSubsectionId = undefined;
var errorIds = [];
var sections = undefined;
var sectionArray = undefined;
var subsections = undefined;
var subsectionArray = undefined;
var questionElements = undefined;
var questionArray = undefined;
var conditionalMenuIndex = -1;
var isDialogOpen = false;
var inputEle = [];
var copyFormRes = {};
var optionalEquipment = '';
var regionArray = [];
var formType = 'all';
const isMockFlow = false;
const formBuilderService = new FormBuilderService();
const formState = new BehaviorSubject({});
const translateFormState = new BehaviorSubject({});
const loaderState = new BehaviorSubject(false);
const openSectionState = new BehaviorSubject(expandedSectionId);
const openTranslateSectionState = new BehaviorSubject(translateSectionId);
const activeElementState = new BehaviorSubject(activeElementId);
const activeTranslateElementState = new BehaviorSubject(activeTranslateElementId);
const currentSubsectionState = new BehaviorSubject(currentSubsectionId);
const translateSubsectionState = new BehaviorSubject(translateSubsectionId)
const conditionalMenuState = new BehaviorSubject(conditionalMenuIndex);
const routeState = new BehaviorSubject(false);
const formValidState = new BehaviorSubject(true);
const showAlertState = new BehaviorSubject(false);
const dialogState = new BehaviorSubject(isDialogOpen);
const sideTocState = new BehaviorSubject(false);
const activeTocElementState = new BehaviorSubject(undefined);
const publishedFormState =  new BehaviorSubject(true);
const copyResponseState = new BehaviorSubject(copyFormRes);
const optionalEquipmentState = new BehaviorSubject(optionalEquipment);
const siteArrayState = new BehaviorSubject([]);
const loggedInState = new BehaviorSubject(false);
const parentDropDownId = new BehaviorSubject('');
const formTypeState = new BehaviorSubject(formType);

class FormStore {

  static _instance = null;

  constructor() {
    this.form = {};
    this.translateForm = {};
    this.formDetails = {
      title: '',
      description: '',
      keywords: [],
      business: '',
      subbusiness: '',
      group: '',
      subgroup: '',
      language: '',
      groupIds: [],
      formRegion: [],
      formSiteRegion: []
    };
  }

  static sharedInstance() {
    if (FormStore._instance == null) {
      FormStore._instance = new FormStore();
    }

    return this._instance;
  }

  copyForm = (form) => {
    currentFormId = form.id;
    this.resetState();
    this.form = form;
    formState.next(form);
  }

  deleteForm = (formId) => {
    this.onLoading(true);
    formBuilderService.deleteForm(formId).then(
      (res) => {
        this.onLoading(false);
        if (res.status !== 200) {
          toast.error("Could not delete form, " + res.message);
          return;
        }
        formListService.fireApi();
        toast.success("Form deleted successfully!");
      }
    );
  }

  load = (formId) => {
    currentFormId = formId;
    this.resetState();
    this.loadFormInternal(formId);
  }

  getErrorIds = () => {
    return errorIds;
  }

  saveForm = () => {
    currentFormId = this.form.id;
    if (this.form !== undefined && currentFormId !== undefined) {
      this.onLoading(true);
      const payload = JSON.parse(JSON.stringify(this.form));
      htmlBase64Utils(payload, true);
      return formBuilderService.updateForm(currentFormId, payload)
        .then((res) => {
          this.onLoading(false);
          if (res.error !== undefined) {
            toast.error("Could not save form, " + res.error);
            return res;
          }
          this.onLoadForm(res.data, true);
          toast.success("Form saved successfuly!");
          return res;
        });
    }
  }

  getCopyFormResponse = (copyFormRes) => {
    copyResponseState.next(copyFormRes);
  }

  saveCopyForm = () => {
    this.onLoading(true);
    formBuilderService.copyForm(this.form).then((res) => {
      this.getCopyFormResponse(res);
      if (res.data) {
        toast.success("Form copied successfuly!");
        this.onLoading(false);
        return;
      }
      else if (res.error !== undefined) {
        // toast.error(res.error.response['data']);
        toast.error("Could not save form, " + res.error);
        this.onLoading(false);
        return;
      } else {
        toast.error("Form could not be created due to errors!")
        this.onLoading(false);
        return;
      }
    });
  }

  getFormDetails = (key, value) => {
    this.formDetails[key] = value;
  }

  getFormCategoryDetails = (key, value, options) => {
    if (options.length > 0) {
      const currentItem = options.find(each => each.categoryName === value);
      if (currentItem) {
        this.formDetails[key] = currentItem.id;
      }
    }
     else {
      this.formDetails[key] = "";
    }
  }

  sendFormDetails = () => {
    return this.formDetails;
  }

  updateFormStatus = (status) => {
    this.form.formStatus = status;
  }

  openSection = (id) => {
    inputEle = [];
    activeTocElementState.next(undefined);
    expandedSectionId = (expandedSectionId !== id) ? id : undefined;
    openSectionState.next(expandedSectionId);
  }

  openTranslateSection = (id) => {
    activeTocElementState.next(undefined);
    translateSectionId = (translateSectionId !== id) ? id : undefined;
    openTranslateSectionState.next(translateSectionId);
  }

  showDialog = (open) => {
    dialogState.next(open);
  }

  toggleTocDrawer = (open, elementId) => {
    sideTocState.next(open)
    activeTocElementState.next(elementId);
  }

  updateFormDetails = (key, value) =>{
    key=(key==="language")?"defaultLanguageCode":key;
    this.form[key]=value
  }

  updateFormCategories = (name, value, existedValues) =>{
    if (existedValues.length > 0) {
      const currentItem = existedValues.find(each=>each.categoryName===value);
      if (currentItem) {
        this.form[name] = currentItem.id;
      }
    }else{
      this.form[name] = "";
    }
  }

  updateFormMetaData = (formtype) =>{
    let formCategories = []
    formCategories.push({"id":this.form.business});
    if(this.form.subbusiness){
      formCategories.push({"id":this.form.subbusiness})
    }
    if(this.form.group){
      formCategories.push({"id":this.form.group})
    }
    if(this.form.subgroup){
      formCategories.push({"id":this.form.subgroup})
    }
    this.form.formCategories = formCategories;
    if (formtype === "updateForm") {
      this.saveForm();
    }
    else if (formtype === "copyForm") {
      this.saveCopyForm();
    }
    this.showDialog(false)
  }


  makeActive = (id) => {
    activeElementId = (activeElementId !== id) ? id : undefined;
    activeElementState.next(activeElementId);
  }

  makeActiveTranslateSection = (id) => {
    activeTranslateElementId = (activeTranslateElementId !== id) ? id : undefined;
    activeTranslateElementState.next(activeTranslateElementId);
  }

  openSubSection = (id) => {
    activeTocElementState.next(undefined);
    currentSubsectionId = (currentSubsectionId !== id) ? id : undefined;
    currentSubsectionState.next(currentSubsectionId);  
  }
   
  openTranslateSubsection = (id) => {
    translateSubsectionId = (translateSubsectionId !== id) ? id : undefined;
    translateSubsectionState.next(translateSubsectionId);
  }

  changeElement = (id, newType) => {
    var updated = FormHelperService.changeElement(this.form, id, newType);
    if (updated) {
      this.onLoadForm(updated);
    }
  }

  getSections = () => {
    return this.form.children;
  }

  getSubSections = (elementId) => {
	  if(this.form.children) {
		  sections = this.form.children.filter(function (section) {
		      return section.elementId === elementId;
	      });
	      sectionArray = sections[0].children;
	      subsectionArray = sectionArray.filter(function (subsection) {
	    	  return subsection.componentType === 'subsection';
	      });
	      return subsectionArray;
	  }
  }

  getQuestionElements = (elementId) => {
    if (elementId && sectionArray) {
      subsections = sectionArray.filter(function (subsection) {
        return subsection.elementId === elementId;
      });
      questionElements = subsections[0].children;
    } else {
    	if(sectionArray) {
	      questionArray = sectionArray.filter(function (question) {
	          return question.componentType !== 'subsection';
	      });
	      questionElements = questionArray;
    	}
    }
    return questionElements;
  }
  
  addSection = (prefix = false,isNotUpdated) => {
    var addedSection = FormHelperService.addNewSectionToForm(expandedSectionId, this.form, prefix);
    var newElement = FormHelperService.addNewElementTo(this.form, addedSection, 'text');
    this.openSection(addedSection.elementId);
    this.onLoadForm(this.form,isNotUpdated);
    expandedSectionId = addedSection.elementId;
    activeElementId = newElement.elementId;
  }

  copySection(existedSection) {
    FormHelperService.copySection(this.form, existedSection);
    this.onLoadForm(this.form);
  }

  addSubSection = () => {
    if (expandedSectionId !== undefined) {
      var element = FormHelperService.addNewElementToActiveSection(this.form, expandedSectionId, 'subsection');
      if (element) {
        FormHelperService.addNewElementToActiveSection(this.form, expandedSectionId, 'text', element.elementId);
        this.onLoadForm(this.form);
        const elementId = element.elementId;
        this.openSubSection(elementId);
        this.makeActive(elementId);
      }
    }
  }

  copySubSection = (selectedSectionId, selectedSubsection, selectedQuestion, copySubsection, position) => {
    if (selectedSectionId !== undefined) {
      var copiedElement = FormHelperService.addCopiedElementtoSelectedSection(this.form, selectedSectionId, selectedSubsection, selectedQuestion, copySubsection, position, expandedSectionId, currentSubsectionId);
      if (copiedElement) {
        this.onLoadForm(this.form);
      }
    }
  }

  copyQuestion = (selectedSectionId, selectedSubsection, selectedQuestion, question, position, type) => {
    if (selectedSectionId !== undefined) {
      var copiedQuestion = FormHelperService.copyOrMoveElementtoSelectedSection(this.form, selectedSectionId, selectedSubsection, selectedQuestion, question, position, expandedSectionId, currentSubsectionId, type);
      if (copiedQuestion) {
        this.onLoadForm(this.form);
      }
    }
  }

  moveQuestion = (selectedSectionId, selectedSubsection, selectedQuestion, question, position, type) => {
    if (selectedSectionId !== undefined) {
      var selectedElement = FormHelperService.copyOrMoveElementtoSelectedSection(this.form, selectedSectionId, selectedSubsection, selectedQuestion, question, position, expandedSectionId, currentSubsectionId, type);
      if (selectedElement) {
        this.onLoadForm(this.form);
      }
      this.makeActive(undefined);
    }
  }

  addElement = (type, subsectionId) => {
    if (expandedSectionId === undefined) { //If no section expanded then make last one
      if (this.form !== undefined && this.form.children.length > 0) {
        const lastSection = this.form.children[this.form.children.length - 1];
        this.openSection(lastSection.elementId);
      }
    }

    if (expandedSectionId !== undefined && type !== undefined) {
      var element = FormHelperService.addNewElementToActiveSection(this.form, expandedSectionId, type, subsectionId);
      if (element) {
        this.onLoadForm(this.form);
        const elementId = element.elementId;
        this.makeActive(elementId);
      }
    }
  }

  deleteSection = (selectedSection) => {
    FormHelperService.removeSection(this.form, selectedSection);
    this.onLoadForm(this.form);
    if (selectedSection.elementId === expandedSectionId) {
      this.openSection(undefined);
    }
  }

  deleteSubsection = (selectedSubsection) => {
    FormHelperService.removeSubsectionFromSection(this.form, expandedSectionId, selectedSubsection);
    this.onLoadForm(this.form);
    if (selectedSubsection.elementId === currentSubsectionId) {
      this.openSubSection(undefined);
    }
  }

  deleteElement = (deleteEle, subsectionId) => {
    FormHelperService.removeElementFromSection(this.form, expandedSectionId, deleteEle, subsectionId);
    this.onLoadForm(this.form);
    if (currentSubsectionId && !subsectionId) {
      this.openSubSection(undefined);
    }
    this.makeActive(undefined);
  }

  getQuestionsList() {
    return FormHelperService.getQuestionsList(this.form, activeElementId);
  }

  selectedAction(optionId) {
    return FormHelperService.selectedActionForOption(this.form, expandedSectionId, activeElementId, currentSubsectionId, optionId);
  }

  getConditional(){
    return FormHelperService.getConditionalfromForm(this.form, expandedSectionId, activeElementId, currentSubsectionId);
  }

  saveOptionQuestions(optionId, targets, conditional) {
    FormHelperService.saveOptionQuestions(this.form, expandedSectionId, activeElementId, currentSubsectionId, optionId, targets, conditional);
  }

  loadFormInternal = (formId) => {
    currentFormId = formId;
    this.onLoading(true);
    if (isMockFlow) {
      this.initializeForm(mockForm);
      return;
    }
    if (currentFormId === undefined) {
      return;
    }
    formBuilderService.getForm(currentFormId).then((res) => {
      this.initializeForm(res.data);
    });
  }

  initializeForm = (formInfo) => {
    this.onLoadForm(formInfo, true);
    if (formInfo.children && formInfo.children.length === 0) {
      this.addSection(false,true);
    }
  }

  onLoadForm = (formInfo, isNotUpdated) => {
    if(expandedSectionId === undefined) {
      this.openSection(undefined);
    }
    if(currentSubsectionId === undefined) {
      this.openSubSection(undefined);
    }
    if(activeElementId === undefined){
      this.makeActive(undefined);
    }
    this.form = formInfo;
    formState.next(formInfo);
    this.onLoading(false);
    if (!isNotUpdated) {
      this.shouldNavigate(true);
    }
    else {
      this.shouldNavigate(false);
    }
  }

  onLoading = (isLoading) => {
    loaderState.next(isLoading);
  }

  resetState = () => {
    currentFormId = undefined;
    expandedSectionId = undefined;
    activeElementId = undefined;
    // if(expandedSectionId == undefined){
    //   openSectionState.next(undefined);
    // }
  }

  shouldNavigate = (isEdited) => {
    routeState.next(isEdited);
  }

  checkFormValid = (isValid) => {
    formValidState.next(isValid);
  }

  //Attributes-related functions:

  addOptions(options) {
    FormHelperService.addOptions(this.form, expandedSectionId, activeElementId, currentSubsectionId, options);
  }

  addRadioGroupOptions(options, conditionals) {
    FormHelperService.addRadioGroupOptions(this.form, expandedSectionId, activeElementId, currentSubsectionId, options, conditionals);
  }
  
  addHelpText(helpText) {
    FormHelperService.addHelpText(this.form, expandedSectionId, activeElementId, currentSubsectionId, helpText);
  }
  
  addPopupOptions(popupOptions) {
    FormHelperService.addPopupOptions(this.translateForm, translateSectionId, activeTranslateElementId, translateSubsectionId, popupOptions);
  }

  addDependentDropdownOptions(dynamicDropdownList) {
    FormHelperService.addDependentDropdownOptions(this.form, expandedSectionId, activeElementId, currentSubsectionId, dynamicDropdownList);
  }

  toggleQuestionAttr(value) {
    FormHelperService.toggleQuestionAttr(this.form, expandedSectionId, activeElementId, currentSubsectionId, value);
    this.onLoadForm(this.form);
  }

  assignUniqueCodeToQuestion(value){
    FormHelperService.assignUniqueCodeToQuestion(this.form, expandedSectionId, activeElementId, currentSubsectionId, value);
    this.onLoadForm(this.form);
  }

  deleteUniqueCodeFromQuestion() {
    FormHelperService.deleteUniqueCodeFromQuestion(this.form, expandedSectionId, activeElementId, currentSubsectionId);
    this.onLoadForm(this.form);
  }

  //Validations-related functions:
  addErrorId(elementId) {
	  if(errorIds.indexOf(elementId) === -1) errorIds.push(elementId);
  }

  deleteErrorId(elementId) {
    if (errorIds.indexOf(elementId) !== -1) {
      let index = errorIds.indexOf(elementId);
      errorIds.splice(index, 1);
    }
  }

  //Conditionals
  showConditionalsMenu = (index) => {
    conditionalMenuIndex = index;
    conditionalMenuState.next(conditionalMenuIndex);
  }

  hideConditionalsMenu = () => {
    conditionalMenuIndex = -1;
    conditionalMenuState.next(conditionalMenuIndex);
  }

   addActionsForOption = (optionId, actions) => {
    FormHelperService.addConditionalsForOption(this.form, expandedSectionId, activeElementId, currentSubsectionId, optionId, actions);
    this.onLoadForm(this.form);
  }

  conditionalsFor = (optionId) => {
    if (optionId === undefined || optionId === null) {
      return null;
    }
    return FormHelperService.conditionalsFor(this.form, expandedSectionId, activeElementId, currentSubsectionId, optionId);
  }

  actionsForOption = (id) => {
    const conditionals = this.conditionalsFor(id);
    if (conditionals) {
      return (conditionals.actions) ? conditionals.actions : [];
    }
    return [];
  }

  updateElementProperties(name, value) {
    FormHelperService.updateElementProperties(this.form, expandedSectionId, activeElementId, currentSubsectionId, name, value);
  }

  updateElementRef(elementId, ref){
    if(ref ){
      inputEle[elementId]=ref;
    }

  }

  getElementRef(elementId){
    return inputEle[elementId];
  }

  // save-transalte-form //
  sendTranslateForm(translateFormInfo,translationToCode) {
    this.translateForm = translateFormInfo;
    this.translateForm.defaultLanguageCode = translationToCode;
    translateFormState.next(translateFormInfo);
  }

  saveTranslateForm = () => {
      if (this.translateForm !== undefined && currentFormId !== undefined) {
        this.onLoading(true);
        formBuilderService.updateTranslateForm(currentFormId, this.translateForm)
          .then((res) => {
            this.onLoading(false);
            if (res.error !== undefined) {
              toast.error("Could not save form, " + res.error);
              return;
            }
            toast.success("Form saved successfuly!");
            let formId = res.data.id;
            this.load(formId);
            return res;
          });
      }
  }

  editPublishedForm = (isLastUpdatedVersion) => {
    publishedFormState.next(isLastUpdatedVersion);
  }

  
  getOptionalEquipment = (optionalEquipment) => {
    optionalEquipmentState.next(optionalEquipment);
  }

  getAllSitesByRegionIds = (regions) => {
    let siteArray = [];
    regionArray = regions.map(region => {
       formBuilderService.getAllSitesByRegion(region.siteGroupId).then(site => {
         siteArray.push({"regionId" : region.siteGroupId , "siteArray" : site.data});
         this.getAllSitesArray(siteArray);
       })
       return region.siteGroupId;
     });
  }

  getAllSitesArray = (siteArray) => {
    if(regionArray.length === siteArray.length){
      siteArrayState.next(siteArray);
    }
  }

  updateLoggingState = (state) => {
    loggedInState.next(state)
  }
  
  parentDropDownId = (parentSelectedObject) => {
    parentDropDownId.next(parentSelectedObject);
  };
  
  addHelpImage(image) {
	let images = FormHelperService.addHelpImage(this.form, expandedSectionId, activeElementId, currentSubsectionId, image);
	this.onLoadForm(this.form);
	return images;
  }
  
  removeHelpImage(imageId) {
	let images = FormHelperService.removeHelpImage(this.form, expandedSectionId, activeElementId, currentSubsectionId, imageId);
	this.onLoadForm(this.form);
	return images;
  }

  removeAllHelpImage() {
    let images = FormHelperService.removeAllHelpImage(this.form, expandedSectionId, activeElementId, currentSubsectionId);
    this.onLoadForm(this.form);
    return images;
    }

  getformType = (formType) => {
    formTypeState.next(formType);
  };
}

export {
  FormStore,
  formState,
  loaderState,
  routeState,
  formValidState,
  showAlertState,
  openSectionState,
  openTranslateSectionState,
  translateSubsectionState,
  activeElementState,
  currentSubsectionState,
  conditionalMenuState,
  dialogState,
  sideTocState,
  activeTocElementState,
  activeTranslateElementState,
  translateFormState,
  publishedFormState,
  copyResponseState,
  optionalEquipmentState,
  siteArrayState,
  loggedInState,
  parentDropDownId,
  formTypeState,
};

const mockForm = {
  "id": 2,
  "formCode": 230,
  "version": 1,
  "title": "New Form - 1",
  "assetType": "asset",
  "formStatus": "Draft",
  "description": "form Description",
  "createdBy": "GE Test user 4",
  "createdDate": 1589959036531,
  "lastUpdatedBy": "GE Test user 4",
  "lastUpdatedDate": 1589959036531,
  "keywords": [
    "renewables"
  ],
  "children": [
    {
      "id": 1,
      "order": 1,
      "elementId": "5b9c0711-1d75-4253-8ff8-11f3ef62f48b",
      "componentType": "section",
      "name": "section_id_1",
      "label": "General Information",
      "buttonIcon": "fa fa-window-maximize",
      "buttonName": "Section",
      "optionsLabel": "Section",
      "enabledActions": [
        "show",
        "hide"
      ],
      "enabledConditions": [],
      "children": [
        {
          "id": 8,
          "motherId": 1,
          "order": 2,
          "elementId": "a0f6cc33-ea9d-4185-95dd-a2547f6b336d",
          "componentType": "textarea",
          "name": "text_area_id_1",
          "label": "Text Area Label",
          "dataType": "string",
          "buttonIcon": "fa fa-font",
          "buttonName": "Text",
          "optionsLabel": "Text",
          "enabledActions": [],
          "enabledConditions": [],
          "children": [],
          "businessUnit": "ren"
        },
        {
          "componentType": "radio",
          "name": "radio_id_1",
          "elementId": "a0f6cc33-ea9d-4185-95dd-qeqeqeqeqe",
          "label": "Radio Group Label",
          "dataType": "string",
          "buttonName": "Radio Button",
          "optionsLabel": "Radio Button",
          "enabledActions": [],
          "enabledConditions": [],
          "children": [],
          "businessUnit": "ren",
          "options": ["Option Label", "Option Label"],
          "validations": { "required": false },
          "properties": { "visible": true, "disabled": false, "answerLabel": "", allowFileUpload: false },
        }
      ]
    },
    {
      "id": 2,
      "order": 2,
      "elementId": "5b9c0711-1d75-4253-8ff8-11f3ef634743",
      "componentType": "section",
      "name": "section_id_1",
      "label": "Additional Information",
      "buttonIcon": "fa fa-window-maximize",
      "buttonName": "Section",
      "optionsLabel": "Section",
      "enabledActions": [
        "show",
        "hide"
      ],
      "enabledConditions": [],
      "children": [
        {
          "id": 8,
          "motherId": 2,
          "order": 2,
          "elementId": "a0f6cc33-ea9d-4185-95dd-a2547f6b34564",
          "componentType": "text",
          "name": "text_id_1",
          "label": "Text Field Label",
          "dataType": "string",
          "buttonIcon": "fa fa-font",
          "buttonName": "Text",
          "optionsLabel": "Text",
          "enabledActions": [],
          "enabledConditions": [],
          "children": [],
          "businessUnit": "ren"
        }
      ]
    }
  ],
  "requests": [],
  "responses": null,
  "formCategories": [
    1
  ],
  "enforceOrder": null,
  "copiedFromFormCode": null,
  "copiedFromFormVersion": null,
  "customFormCode": null,
  "isInLine": false,
  "formSupportedLanguages": [],
  "defaultLanguageCode": "en",
  "componentType": "form",
  "hashCode": -448201537
};