import React, {useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormBuilderService from '../../services/form-builder.service';
import { Alert } from '@material-ui/lab';
import { ValidationHelperService } from '../../services/validation-helper.service';
import { toast } from 'react-toastify';

let formBuilderService = new FormBuilderService();

export default function CreateUserGroup(props) {
  const [selectedForms ,setSelectedForms] = useState([]);
  const [selectedUsers ,setSelectedUsers] = useState([]);
  const [groupName ,setGroupName] = useState("");
  const [hasError ,setHasError] = useState(false);
  const [error ,setError] = useState("");

  const useStyles = makeStyles((theme) => ({
      dialogPaper: {
        position: 'absolute', top: 34, minWidth: '60%',
      },
  }));

  const handleCancel = ()=>{
    props.handleClose();
  }

  const onTagsChange = (event, values) => {
      setSelectedForms(values)
  }

  const handleSave = () => {
    const isValid = validateForm();
    if (isValid) {
      let formCodes = [];
      let userIds = [];
      selectedForms.forEach(form => formCodes.push({"formCode": form.formCode}));
      selectedUsers.forEach(user => userIds.push({"id": user.id}));
      formBuilderService.createUserGroup({"groupName":groupName,"forms":formCodes,"users": userIds}).then(res=>{
        if(res.response && res.response.data) {
          setError(res.response.data.message);
          return;
        } else if(res.data) {
          toast.success(groupName + " group created successfuly!");
        } else {
          toast.error(groupName + " group could not be created due to errors!");
        }
        props.handleUpdate();
        return;
      })
    }
    setHasError(true);
  }

  const clearData = () => {
    setError("");
    setGroupName("");
    setHasError(false);
    setSelectedForms([]);
    setSelectedUsers([]);
  }

  const validateForm = () => {
    var valid = false;
    if (groupName && selectedForms.length && selectedUsers.length) {
      valid = true;
    }
    return valid;
  }

  const changeHandler = (e) =>{
    setGroupName(e.target.value)
  }

  const onUsersChange  = (event, values) => {
    setSelectedUsers(values)
  }

  const getHelperTextForName=(value) =>{
    let helperText = "";
    if (hasError) {
      if (!value) {
        helperText = "Group Name is required!";
      }
      else if (!alphanumericValidator(value)) {
        helperText = "Group Name should be alphanumeric.";
      }
    }
    return helperText;
  }

  const getHelperTextForForms = () =>{
    let helperText = "";
    if (hasError &&  !selectedForms.length) {
        helperText = "Forms is required!";
    }
    return helperText;

  }

  const getHelperTextForUsers = () => {
    let helperText = "";
    if(hasError && !selectedUsers.length){
      helperText = "Users are required"
    }
    return helperText;
  }

  const alphanumericValidator = (value) => {
    return  ValidationHelperService.alphanumericValidator(value);
  }

  const styles = (theme) => ({
      root: {
        margin: 0,
      },
      closeButton: {
          position: 'absolute', right: theme.spacing(4), top: theme.spacing(2),
          color: theme.palette.grey[500]
      }
  });

  const DialogTitle = withStyles(styles)((propsDT) => {
      const { children, classes, onClose, ...other } = propsDT;
      return (
        <MuiDialogTitle id="muiDialog" disableTypography 
          className={classes.root} {...other}>
          <Typography variant="h5">{children}</Typography>
          {onClose ? (
            <IconButton id="iconButton" aria-label="close" 
              className={classes.closeButton} onClick={onClose}><CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }} onEnter={clearData} >
        <DialogTitle id="alert-dialog-title" onClose={handleCancel} >
        	{"New Group"}
        </DialogTitle>
        <DialogContent>
            {error ? <Alert id="alert" style={{ marginBottom:20}} 
            	severity="error">{error}</Alert> : null}
            <Box p={3}>
              <Grid container spacing={3} >
                  <TextField fullWidth label="Group Name" name="title"
                    value={groupName} id="groupName" onChange={changeHandler}
                    required variant="outlined" 
                    error={hasError && ((!groupName) || !alphanumericValidator(groupName)) }
                    helperText={getHelperTextForName(groupName)} />
              </Grid>
            </Box>
            <Box p={3}>
              <Grid container spacing={3} >
                  <Autocomplete multiple id="checkboxes-tags-demo"
                      options={props.usersList} onChange={onUsersChange}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.userFirstName}
                      renderTags={(value, getTagProps) => value.map((option, index) =>
                        (<Chip color="secondary"
                          id="chip"
                          size="small"
                          label={option.userFirstName} {...getTagProps({ index })} />))}
                      renderOption={(option, { selected }) => (
                          <React.Fragment>
                          <Checkbox
                              id="checkboxIcon"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                          />
                          {option.userFirstName}
                          </React.Fragment>
                      )}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                          <TextField {...params}
                            id="userTextField" 
                            variant="outlined" 
                            label="Users" 
                            placeholder="Select Users" 
                            required
                            error={hasError && !selectedUsers.length }
                            helperText={getHelperTextForUsers()}
                          />
                      )}
                  />
                </Grid>
            </Box>
            <Box p={3}>
              <Grid container spacing={3} >
                  <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={props.formsList}
                      onChange={onTagsChange}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.formName}
                      renderTags={(value, getTagProps) => value.map((option, index) =>
                        (<Chip color="secondary"
                          size="small"
                          id="chip"
                          label={option.formName} {...getTagProps({ index })} />))}
                      renderOption={(option, { selected }) => (
                          <React.Fragment>
                          <Checkbox id="checkboxIcon" icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }} checked={selected}
                          />
                          {option.formName}
                          </React.Fragment>
                      )}
                      style={{ width:"100%" }}
                      renderInput={(params) => (
                          <TextField {...params} id="formTextField"
                            variant="outlined" label="Forms" 
                            placeholder="Select Forms" required 
                            error={hasError && !selectedForms.length }
                            helperText={getHelperTextForForms()}
                          />
                      )}
                  />
              </Grid>
            </Box>
        </DialogContent>
        <DialogActions style={{padding: "8px 24px 14px 24px"}}>
          <Button variant="contained" color="secondary" onClick={handleCancel} >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}